import ClientPartitionModel               from 'Models/directory/ClientPartitionModel';
import Select                             from 'antd/lib/select';
import { observer }                       from 'mobx-react';
import { NavigateFunction }               from 'react-router-dom';
import { useNavigate }                    from 'react-router-dom';
import React                              from 'react';
import { getIdFromUrn }                   from 'tools/UrnTools';
import {
	appStore,
}                                   from '../../stores';

interface ITaskZoneFilterProps {
	disabled?: boolean;
	navigate: NavigateFunction;
}

@observer
class TaskZoneFilter extends React.Component<ITaskZoneFilterProps> {
	public render() {
		if (appStore.isLoading) {
			return null;
		}

		return (
			<div id="task-zone-filter">
				<Select 
					allowClear={false}
					onChange={(value) => {
						appStore.setSelectedEnterprise(value);
						appStore.setSelectedClientPartition(undefined);
						appStore.setSelectedTaskZone(undefined);
						this.props.navigate('/enterprises/' + getIdFromUrn(value));
					}}
					options={appStore.enterpriseCollection.map(ep => ({
						label: ep.name,
						value: ep.urn,
					}))}
					placeholder="Choisissez une entreprise"
					style={{ width: 200 }}
					value={appStore.enterpriseUrn}
				/>
				{appStore.enterpriseUrn && (
					<Select 
						allowClear={false}
						onChange={(value) => {
							appStore.setSelectedClientPartition(value);
							appStore.setSelectedTaskZone(undefined);
							this.props.navigate('/enterprises/' + getIdFromUrn(appStore.enterpriseUrn!) + '/establishments/' + getIdFromUrn(value));
						}}
						options={appStore.clientPartitionCollection.map(cp => ({
							label: cp.company.name,
							value: cp.urn,
						}))}
						placeholder="Choisissez un établissement"
						style={{ marginLeft: '.5rem', width: 200 }}
						value={appStore.clientPartitionUrn}
					/>
				)}
				{appStore.clientPartitionUrn && (
					<Select 
						allowClear={false}
						onChange={(value) => {
							appStore.setSelectedTaskZone(value);
							this.props.navigate('/enterprises/' + getIdFromUrn(appStore.enterpriseUrn!) + '/establishments/' + getIdFromUrn(appStore.clientPartitionUrn!) + '/zones/' + getIdFromUrn(value));
						}}
						options={this.getTaskZonesOptions(appStore.clientPartitionCollection.find(cp => cp.urn === appStore.clientPartitionUrn)!)}
						placeholder="Choisissez un site"
						style={{ marginLeft: '.5rem', width: 200 }}
						value={appStore.taskZoneUrn}
					/>
				)}
			</div>
		);
	}

	private getTaskZonesOptions = (clientPartition: ClientPartitionModel) => {
		const {
			taskZoneAutoloadedCollection,
			taskZoneGroupCollection,
			taskZoneOwnerCollection,
		} = appStore;

		const taskZones = taskZoneAutoloadedCollection.filter(tz => {
			const owner = taskZoneOwnerCollection.find(owner => owner.id === tz.ownerId);

			return owner?.clientPartitionId === clientPartition.id;
		});

		return taskZones.map(tz => {
			let label = tz.name;

			if (tz.taskZoneGroupId) {
				const taskZoneGroup = taskZoneGroupCollection.find(
					taskZoneGroup => taskZoneGroup.id === tz.taskZoneGroupId,
				);

				if (taskZoneGroup) {
					label = taskZoneGroup.name + ' > ' + label;
				}

			}

			return {
				label,
				value: tz.urn,
			};
		});
	};
}



const TaskZoneFilterWithHistory = (props: Omit<ITaskZoneFilterProps, 'navigate'>) => {
	const navigate = useNavigate();

	return <TaskZoneFilter {...props} navigate={navigate} />;
};

export default TaskZoneFilterWithHistory;