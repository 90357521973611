import Form                              from 'antd/lib/form';
import Select                            from 'antd/lib/select';
import usePutUrlParams                   from 'hooks/usePutUrlParams';
import useUrlParam                       from 'hooks/useUrlParam';
import { observer }                      from 'mobx-react';
import React, { useEffect }              from 'react';
import { appStore }                      from '../../stores';

function PurchaseOrderFilter() {
	const {
		purchaseOrderCollection,
		purchaseOrderUrnFilter,
	} = appStore;

	const isLoading = (
		purchaseOrderCollection.isLoading
	);

	const putUrlParams = usePutUrlParams();
	const defaultUrn = useUrlParam('purchaseOrderUrn');

	useEffect(() => {
		if (defaultUrn) {
			appStore.setPurchaseOrderUrnFilter(defaultUrn);
		}
	}, []);

	return (
		<Form
			initialValues={{ urn: purchaseOrderUrnFilter }}
			style={{ display: 'flex', gap: '0 1rem' }}
		>
			<Form.Item
				label="Bon de commande"
				name="urn"
				style={{ width: 300 }}
			>
				<Select
					allowClear
					loading={isLoading}
					onChange={urn => {
						appStore.setPurchaseOrderUrnFilter(urn);
						putUrlParams({ 'purchaseOrderUrn': urn });
					}}
					placeholder="Bon de commande"
				>
					{purchaseOrderCollection
						.map(purchaseOrder => {
							return (
								<Select.Option
									key={purchaseOrder.urn}
									value={purchaseOrder.urn}
								>
									{purchaseOrder.reference}
								</Select.Option>
							);
						})
					}
				</Select>
			</Form.Item>
		</Form>
	);
}

export default observer(PurchaseOrderFilter);
