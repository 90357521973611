import Card         from 'antd/lib/card';
import { Page }     from 'components/Page';
import { Link }     from 'react-router-dom';
import React        from 'react';
import ConfigProxy  from 'tools/ConfigProxy';
import LoginForm    from '../../forms/LoginForm';
import PublicLayout from '../../projectComponents/PublicLayout';

const LoginPage = () => {
	let title = '';
	switch (ConfigProxy.get('PARTITION_URN')) {
		case '$:partition:partition:1':
			title = 'Qualité - Hygiène - Services';
			break;
		case '$:partition:partition:2':
			title = 'DICOP';
			break;
		case '$:partition:partition:3':
			title = 'HPR';
			break;
		case '$:partition:partition:4':
			title = 'HPR Aquitaine';
			break;
		case '$:partition:partition:5':
			title = 'CG2D';
			break;
		case '$:partition:partition:6':
			title = 'RControlClim';
			break;
		case '$:partition:partition:7':
			title = 'IGIENAIR';
			break;
		case '$:partition:partition:8':
			title = 'ADI AMS';
			break;
		case '$:partition:partition:9':
			title = 'AIR SN';
			break;
		case '$:partition:partition:13':
			title = 'NANOSENSE';
			break;
		case '$:partition:partition:14':
			title = 'ADDD';
			break;
		case '$:partition:partition:15':
			title = 'AGETEP';
			break;
		default:
		// require('./AntdCustomTheme.less');
	}

	return (
		<PublicLayout title={title}>
			<Page title="Connexion">
				<Card>
					<LoginForm />
				</Card>

				<Link
					style={{ marginTop: 30, textAlign: 'center' }}
					to="/forgotten-password"
				>
					Mot de passe oublié ?
				</Link>
			</Page>
		</PublicLayout>
	);
};

export default LoginPage;
