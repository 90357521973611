import QuotationStatusModel from '../modelx/models/private/sales/QuotationStatusModel';

export const QUOTATION_STATUS_BEING_CREATED = {
	label: 'En cours de création',
	reference: 'being_created',
};

export const QUOTATION_STATUS_CANCELED = {
	label: 'Annulé',
	reference: 'canceled',
};

export const QUOTATION_STATUS_IN_VALIDATION = {
	label: 'En validation',
	reference: 'in_validation',
};

export const QUOTATION_STATUS_VALIDATED = {
	label: 'Validé',
	reference: 'validated',
};

export const QUOTATION_STATUS_SENT_TO_CLIENT = {
	label: 'Envoyé au client',
	reference: 'sent_to_client',
};

export const QUOTATION_STATUS_VALIDATED_BY_CLIENT = {
	label: 'Validé par le client',
	reference: 'validated_by_client',
};

export const QUOTATION_STATUS_PRE_ACTIVATED = {
	label: 'Pré-activé',
	reference: 'pre_activated',
};

export const QUOTATION_STATUS_ACTIVATED = {
	label: 'Activé',
	reference: 'activated',
};

export const QUOTATION_STATUS_SUSPENDED = {
	label: 'Suspendu',
	reference: 'suspended',
};

export const QUOTATION_STATUS_CLOSED = {
	label: 'Clôturé',
	reference: 'closed',
};

export const QUOTATION_STATUS_TERMINATED = {
	label: 'Terminé',
	reference: 'terminated',
};

export const QUOTATION_STATUS_BLOCKED = {
	label: 'Bloqué',
	reference: 'blocked',
};

export const quotationPreviewableStatuses = [
	QUOTATION_STATUS_BEING_CREATED,
	QUOTATION_STATUS_IN_VALIDATION,
	QUOTATION_STATUS_VALIDATED,
];

export const getQuotationStatusColor = (quotationStatus: QuotationStatusModel) => {
	switch (quotationStatus.reference) {
		case QUOTATION_STATUS_BEING_CREATED.reference:
		case QUOTATION_STATUS_CANCELED.reference:
		case QUOTATION_STATUS_IN_VALIDATION.reference:
		case QUOTATION_STATUS_VALIDATED.reference:
		case QUOTATION_STATUS_VALIDATED_BY_CLIENT.reference:
		case QUOTATION_STATUS_PRE_ACTIVATED.reference:
		case QUOTATION_STATUS_BLOCKED.reference :
			return 'grey';
		case QUOTATION_STATUS_CLOSED.reference:
			return 'red';
		case QUOTATION_STATUS_SENT_TO_CLIENT.reference:
			return 'blue';
		case QUOTATION_STATUS_SUSPENDED.reference:
			return 'orange';
		case QUOTATION_STATUS_ACTIVATED.reference:
			return 'green';
		case QUOTATION_STATUS_TERMINATED.reference:
			return 'purple';
	}
};

export default {
	QUOTATION_STATUS_ACTIVATED,
	QUOTATION_STATUS_BEING_CREATED,
	QUOTATION_STATUS_BLOCKED,
	QUOTATION_STATUS_CANCELED,
	QUOTATION_STATUS_CLOSED,
	QUOTATION_STATUS_IN_VALIDATION,
	QUOTATION_STATUS_PRE_ACTIVATED,
	QUOTATION_STATUS_SENT_TO_CLIENT,
	QUOTATION_STATUS_SUSPENDED,
	QUOTATION_STATUS_TERMINATED,
	QUOTATION_STATUS_VALIDATED,
	QUOTATION_STATUS_VALIDATED_BY_CLIENT,
};