import { ApiCollection }                        from 'Collections/ApiCollection';
import ActivityCategoryModel                    from 'Models/sales/ActivityCategoryModel';
import PurchaseOrderTargetModel                 from 'Models/sales/PurchaseOrderTargetModel';
import {
	CONTRACT_ITERATION_STATUS_ACTIVATED,
	CONTRACT_ITERATION_STATUS_BLOCKED,
	CONTRACT_ITERATION_STATUS_SENT_TO_CLIENT,
}                             					from 'constants/ContractIterationStatuses';
import { computed }                             from 'mobx';
import TaskZoneModel                            from '../modelx/models/private/intervention/TaskZoneModel';
import ContractIterationModel                   from '../modelx/models/private/sales/ContractIterationModel';
import ContractModel                            from '../modelx/models/private/sales/ContractModel';
import { appStore }                             from '.';

export default class ContractsStore implements Store {
	
	public activityCategoryCollection = new ApiCollection(ActivityCategoryModel);
	public contractCollection = new ApiCollection(ContractModel);
	public contractIterationCollection = new ApiCollection(ContractIterationModel);
	
	private _contractIterationStatusUrns: string[] = [];
	private _defaultStatusReferences = [
		CONTRACT_ITERATION_STATUS_ACTIVATED.reference,
		CONTRACT_ITERATION_STATUS_BLOCKED.reference,
		CONTRACT_ITERATION_STATUS_SENT_TO_CLIENT.reference,
	];
	
	private _purchaseOrderContractIterationUrns: string[] | undefined;
	private _taskZoneUrn = '';


	public clear() {
		this.contractCollection.clear();
		this.contractIterationCollection.clear();
	}

	public async fetch(): Promise<void> {
		this.contractIterationCollection.setFilters({
			archived: false,
			'contractIterationStatus.reference': this._contractIterationStatusUrns.length === 0 ? this._defaultStatusReferences : (
				appStore.contractIterationStatusCollection
					.filter(value => this._contractIterationStatusUrns.includes(value.urn))
					.map(value => value.reference)
			),
			id: this._purchaseOrderContractIterationUrns,
			'items.itemActivities.locationUrn': this._taskZoneUrn,
		});

		await Promise.all([
			this.contractIterationCollection.list().then(() => {
				return this.contractCollection.listByFromCollection(this.contractIterationCollection, 'contractId', 'id');
			}),
			this.activityCategoryCollection.list({ cache: 3600, }),
		]);
	}

	public setContractIterationStatusUrnFilterAndFetch = (urns: string[]) => {
		this._contractIterationStatusUrns = urns;
		this.fetch();
	};

	public setPurchaseOrderUrnFilterAndFetch = async (urn: Urn) => {
		const purchaseOrderTargetCollection = new ApiCollection(PurchaseOrderTargetModel);
		await purchaseOrderTargetCollection.listBy([urn], 'purchaseOrder');
		this._purchaseOrderContractIterationUrns = purchaseOrderTargetCollection.map((target) => target.targetUrn);
		this.fetch();
	};

	public setTaskZoneFilterAndFetch = (taskZone: TaskZoneModel) => {
		this._taskZoneUrn = taskZone.urn;
		this.fetch();
	};

	@computed
	public get isLoading(): boolean {
		return (
			this.contractCollection.isLoading ||
			this.contractIterationCollection.isLoading
		);
	}
}
