import Form                                  from 'antd/lib/form';
import Select                                from 'antd/lib/select';
import Spin                                  from 'antd/lib/spin';
import { QUOTATION_STATUS_ACTIVATED }        from 'constants/QuotationStatuses';
import { QUOTATION_STATUS_BLOCKED }          from 'constants/QuotationStatuses';
import { QUOTATION_STATUS_SENT_TO_CLIENT }   from 'constants/QuotationStatuses';
import { observer }                          from 'mobx-react';
import React                                 from 'react';
import { appStore }                          from '../../stores';

function QuotationFilter() {
	const {
		quotationStatusCollection,
		quotationStatusUrnFilter,
	} = appStore;

	const isLoading = (
		quotationStatusCollection.isLoading
	);

	if (isLoading) {
		return <Spin />;
	}

	return (
		<Form
			initialValues={{ urn: quotationStatusUrnFilter || [
				quotationStatusCollection.findBy('reference', QUOTATION_STATUS_ACTIVATED.reference)?.urn,
				quotationStatusCollection.findBy('reference', QUOTATION_STATUS_BLOCKED.reference)?.urn,
				quotationStatusCollection.findBy('reference', QUOTATION_STATUS_SENT_TO_CLIENT.reference)?.urn
			] }}
		>
			<Form.Item
				label="Statut"
				name="urn"
				style={{ width: 400 }}
			>
				<Select
					allowClear
					loading={isLoading}
					mode="multiple"
					onChange={urns => appStore.setQuotationStatusUrnFilter(urns)}
					placeholder="Statut"
				>
					{quotationStatusCollection
						.filter(status => ['sent_to_client', 'activated', 'closed', 'blocked'].includes(status.reference))
						.map(status => {
							return (
								<Select.Option
									key={status.urn}
									value={status.urn}
								>
									{status.label}
								</Select.Option>
							);
						})
					}
				</Select>
			</Form.Item>
		</Form>
	);
}

export default observer(QuotationFilter);
