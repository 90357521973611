import { DownloadOutlined }  from '@ant-design/icons';
import { ApiCollection }     from 'Collections/ApiCollection';
import { FileTypeReference } from 'Models/file/FileModel';
import FileModel             from 'Models/file/FileModel';
import Button                from 'antd/lib/button';
import DashboardListBox      from 'components/DashboardListBox';
import React                 from 'react';

const FileListBox = ({
	references,
	servicesUrns,
	sourcesUrns,
}: {
	references: FileTypeReference[];
	servicesUrns: string[];
	sourcesUrns: string[];
}) => {
	const fileCollection = new ApiCollection(FileModel);

	React.useEffect(() => {
		fileCollection.setFilters({
			'typeSource.source.entityUrn': sourcesUrns,
			'typeSource.type.reference': references,
			'typeSource.type.service.serviceUrn': servicesUrns,
		});
		fileCollection.list();
	});

	return (
		<DashboardListBox
			boxTitle="Documents"
			columns={[
				{
					render: model => {
						return <div>{model.id}</div>;
					},
					title: 'Preview',
				},
				{
					render: model => {
						return (
							<div>
								<strong>{model.filename}</strong><br />
								Description {model.description}<br />
								<i>Créé le {model.createdAtFormatted}</i><br />
							</div>
						);
					},
					title: 'Nom',
				},
				{
					render: model => {
						return <Button
							icon={<DownloadOutlined />}
							onClick={() => {
								model.download();
							}}
						/>;
					},
					title: 'Download',
				}

			]}
			dataSource={fileCollection.models}
			loading={fileCollection.isLoading}
			showHeader={false}
		/>
	);
};

export default FileListBox;
